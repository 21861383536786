import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { Text } from "@components/ui";
import style from "./BlocSeo.module.scss";
/**
 * Props for `BlocSeo`.
 */
export type BlocSeoProps = SliceComponentProps<Content.BlocSeoSlice>;

/**
 * Component for "BlocSeo" Slices.
 */
const BlocSeo = ({ slice }: BlocSeoProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.section}
    >
      <div className="lk-wrap">
        <div className={style.row}>
          <div className={style.col}>
            <h3>
              <PrismicRichText field={slice.primary.leftTitle} />
            </h3>
            <Text>
              <PrismicRichText field={slice.primary.leftText} />
            </Text>
          </div>
          <div className={style.col}>
            <h3>
              <PrismicRichText field={slice.primary.rightTitle} />
            </h3>
            <Text>
              <PrismicRichText field={slice.primary.rightText} />
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlocSeo;
